const CheckWin = (boardState) => {
  let potentialWinArrays = [];
  for (let loop = 0; loop < 3; loop++) {
    let horizontalSubArray = [];
    let verticalSubArray = [];
    for (let index = 0; index < 9; index++) {
      if (index % 3 === loop) {
        horizontalSubArray.push(index);
      }
      if (Math.floor(index / 3) === loop) {
        verticalSubArray.push(index);
      }
    }
    potentialWinArrays.push(horizontalSubArray);
    potentialWinArrays.push(verticalSubArray);
    potentialWinArrays.push([0, 4, 8]); //Add diagonals
    potentialWinArrays.push([2, 4, 6]);
  }
  for (let i = 0; i < potentialWinArrays.length; i++) {
    let checkedArray = [];
    for (let index = 0; index < 3; index++) {
      checkedArray.push(boardState[potentialWinArrays[i][index]]);
    }
    const set = new Set(checkedArray);
    if (set.size === 1 && set.values().next().value !== "") {
      const winningMove = set.values().next().value;
      const messageBox = document.getElementsByClassName("Message-Box");
      const resetButton = document.getElementsByClassName("End-Turn-Button");
      messageBox[0].innerHTML = `${winningMove} Wins. Play again?`;
      resetButton[0].style = "block";
      return true;
    }
  }
  return false;
};

export default CheckWin;
