import React from "react";
import "../App.css";
import constructionSite from "../components/constructionSite.png";
import PageTransition from "../functions/pageTransition";
import { Link } from "react-router-dom";

const GamePage = () => {
  const linkStyle = {
    color: "white",
    border: "2px solid white",
    margin: "5px",
    padding: "8px 12px",
    textDecoration: "none",
    display: "block",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between", // Distribute content between columns
    alignItems: "center",
    minHeight: "100vh",
    padding: "0 20px", // Added padding for spacing
  };

  const leftColumnStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const rightColumnStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return PageTransition(
    <header className="App-header">
      <div className="App" style={containerStyle}>
        <div style={leftColumnStyle}>
          <Link to="/nim" style={linkStyle}>
            <b>Nim</b>
          </Link>
          <Link to="/tictactoe" style={linkStyle}>
            <b>TicTacToe</b>
          </Link>
        </div>
        <div style={rightColumnStyle}>
          <p>Under Construction</p>
          <Link to="/face">
            <img
              src={constructionSite}
              className="Under-construction"
              alt="workers in a spooky city"
            ></img>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default GamePage;
