import React, { useState, useEffect } from "react";
import "../../App.css";
import PageTransition from "../../functions/pageTransition";
import HeaderBar from "../../components/headerBar";
import structureLookup from "./structureLookup";
import unitLookup from "./unitLookup";
import lodash from "lodash";
import buildSim from "./buildSim";
import validateRequirements from "./validateRequirements";
import tableComponent from "../../components/tableComponent";

const BuildOrderPage = () => {
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedStructure, setSelectedStructure] = useState("");
  const [selectedSpeed, setSelectedSpeed] = useState("Faster");

  const [selectedRace, setSelectedRace] = useState("zerg");

  const [runTime, setRunTime] = useState("");

  const [currentUnitList, setUnitList] = useState([
    ...Array.from({ length: 12 }, () => lodash.cloneDeep(unitLookup.drone)),
    lodash.cloneDeep(unitLookup.overlord),
  ]);

  const [currentStructureList, setCurrentStructureList] = useState([
    lodash.cloneDeep(structureLookup.hatchery),
  ]);

  let startingUnitList = [
    ...Array.from({ length: 12 }, () => lodash.cloneDeep(unitLookup.drone)),
    lodash.cloneDeep(unitLookup.overlord),
  ];
  let startingStructureList = [lodash.cloneDeep(structureLookup.hatchery)];

  const [buildOrder, setBuildOrder] = useState([]);

  const [currentSupply, setCurrentSupply] = useState(0);
  const [currentSupplyCap, setCurrentSupplyCap] = useState(0);

  const handleUnitSelect = (event) => {
    setSelectedUnit(event.target.value);
  };

  const handleStructureSelect = (event) => {
    setSelectedStructure(event.target.value);
  };

  useEffect(() => {
    const totalSupply = currentUnitList.reduce(
      (total, unit) => total + unit.supplyCost,
      0
    );
    setCurrentSupply(totalSupply);

    const structureSupply = currentStructureList.reduce(
      (total, structure) => total + structure.supplyProvided,
      0
    );

    const overlordSupply = currentUnitList.reduce(
      (total, unit) => total + unit.supplyProvided,
      0
    );

    let calculatedSupplyCap = structureSupply + overlordSupply;

    if (calculatedSupplyCap > 200) {
      calculatedSupplyCap = 200;
    }

    setCurrentSupplyCap(calculatedSupplyCap);
  }, [currentUnitList, currentStructureList]);

  const buildItem = (itemType) => {
    let newItem;
    let updatedSupply;
    if (itemType === "structure" && selectedStructure) {
      newItem = lodash.cloneDeep(structureLookup[selectedStructure]);
    } else if (itemType === "unit" && selectedUnit) {
      newItem = lodash.cloneDeep(unitLookup[selectedUnit]);
      updatedSupply = currentSupply + newItem.supplyCost;
    } else {
      return;
    }
    const {
      convertedFromStructureFound,
      unitFound,
      requiredStructureFound,
      productionStructureFound,
    } = validateRequirements(newItem, currentStructureList, currentUnitList);
    if (
      !convertedFromStructureFound ||
      !requiredStructureFound ||
      !productionStructureFound
    ) {
      console.log("Missing Structure Requirement");
      return;
    } else if (!unitFound) {
      console.log("Missing Unit Requirement");
      return;
    } else if (itemType === "unit" && updatedSupply > currentSupplyCap) {
      console.log("Not Enough Supply");
      return;
    } else {
      if (newItem.convertedFromStructure) {
        let index = currentStructureList.findIndex(
          (x) => x.name === newItem.convertedFromStructure
        );
        currentStructureList.splice(index, 1);
      }
      if (newItem.convertedFromUnit) {
        let index = currentUnitList.findIndex(
          (x) => x.name === newItem.convertedFromUnit
        );
        currentUnitList.splice(index, 1);
      }

      if (itemType === "unit") {
        setUnitList([...currentUnitList, newItem]);
      } else {
        setCurrentStructureList([...currentStructureList, newItem]);
      }
      setCurrentSupplyCap(currentSupply + newItem.supplyProvided);
      setBuildOrder([...buildOrder, newItem]);
    }
  };

  const addStructure = () => {
    if (selectedStructure) {
      buildItem("structure");
    }
  };

  const addUnit = () => {
    if (selectedUnit) {
      buildItem("unit");
    }
  };

  const unitOptions = Object.keys(unitLookup).filter(
    (key) => key !== "Unit" && unitLookup[key].race === selectedRace
  );

  const structureOptions = Object.keys(structureLookup).filter(
    (key) => key !== "Structure" && structureLookup[key].race === selectedRace
  );

  const buildButtonAction = () => {
    if (buildOrder.length > 0) {
      const speedMultipliers = {
        Faster: 1.4,
        Fast: 1.2,
        Normal: 1,
        Slow: 0.8,
        Slower: 0.6,
      };

      const speedMultiplier = speedMultipliers[selectedSpeed] || 1;
      setRunTime(
        Math.round(
          buildSim(buildOrder, startingUnitList, startingStructureList) *
            (1 / speedMultiplier)
        )
      );
    }
  };

  const handleSpeedSelect = (event) => {
    setSelectedSpeed(event.target.value);
  };

  const resetState = (newRace) => {
    setSelectedUnit("");
    setSelectedStructure("");

    setRunTime("");

    switch (newRace) {
      case "zerg":
        setUnitList([
          ...Array.from({ length: 12 }, () =>
            lodash.cloneDeep(unitLookup.drone)
          ),
          lodash.cloneDeep(unitLookup.overlord),
        ]);
        setCurrentStructureList([lodash.cloneDeep(structureLookup.hatchery)]);
        break;
      case "protoss":
        setUnitList([
          ...Array.from({ length: 12 }, () =>
            lodash.cloneDeep(unitLookup.probe)
          ),
        ]);
        setCurrentStructureList([lodash.cloneDeep(structureLookup.nexus)]);
        break;
      case "terran":
        setUnitList([
          ...Array.from({ length: 12 }, () => lodash.cloneDeep(unitLookup.scv)),
        ]);
        setCurrentStructureList([
          lodash.cloneDeep(structureLookup.commandCenter),
        ]);
        break;
    }

    setBuildOrder([]);

    setCurrentSupply(0);
    setCurrentSupplyCap(0);

    startingUnitList = lodash.cloneDeep(currentUnitList);
    startingStructureList = lodash.cloneDeep(currentStructureList);
  };

  const handleRaceSelect = (event) => {
    const newRace = event.target.value;
    setSelectedRace(newRace);
    resetState(newRace);
  };

  return PageTransition(
    <div className="App">
      <HeaderBar />
      <header className="App-header">
        <div style={{ paddingLeft: "1vmin" }}>
          {tableComponent(currentStructureList, "Current Structures")}
        </div>
        <div style={{ paddingLeft: "1vmin" }}>
          {tableComponent(currentUnitList, "Current Units")}
        </div>
        <div style={{ paddingLeft: "1vmin" }}>
          {tableComponent(buildOrder, "Build Order")}
        </div>
        <div style={{ paddingLeft: "1vmin" }}>
          <div style={{ paddingBottom: "1vmin" }}>
            <select onChange={handleRaceSelect}>
              <option value="zerg">zerg</option>
              <option value="protoss">protoss</option>
              <option value="terran">terran</option>
            </select>
          </div>
          <div>
            <b>Supply</b>
            <p>
              {currentSupply}/{currentSupplyCap}
            </p>{" "}
          </div>
          <div style={{ paddingLeft: "5vmin" }}>
            <select value={selectedUnit} onChange={handleUnitSelect}>
              <option value="">Select Unit</option>
              {unitOptions.map((key) => (
                <option key={key} value={key}>
                  {unitLookup[key].name}
                </option>
              ))}
            </select>
            <button onClick={addUnit}>Add Unit</button>
          </div>
          <div>
            <select value={selectedStructure} onChange={handleStructureSelect}>
              <option value="">Select Structure</option>
              {structureOptions.map((key) => (
                <option key={key} value={key}>
                  {structureLookup[key].name}
                </option>
              ))}
            </select>
            <button onClick={addStructure}>Add Structure</button>
          </div>
        </div>
        <div>
          <select value={selectedSpeed} onChange={handleSpeedSelect}>
            <option value="Faster">Faster</option>
            <option value="Fast">Fast</option>
            <option value="Normal">Normal</option>
            <option value="Slow">Slow</option>
            <option value="Slower">Slower</option>
          </select>
          <button onClick={buildButtonAction}>Run Sim</button>
          <p>Run Time: {runTime} Seconds</p>
        </div>
      </header>
    </div>
  );
};

export default BuildOrderPage;
