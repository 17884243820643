const HumanMove = (event, boardState, turn) => {
  const cellValue =
    event.currentTarget.querySelector(".Cell-Value").textContent;
  const cellId = Number(
    event.currentTarget.id.charAt(event.currentTarget.id.length - 1)
  );
  let movePiece = turn % 2 === 1 ? "X" : "O";

  if (cellValue === "") {
    event.currentTarget.querySelector(".Cell-Value").textContent = movePiece;
    boardState[cellId] = movePiece;
    const chosenColor = `${movePiece}-Color`;
    event.currentTarget.classList.add(chosenColor);
  }
};

export default HumanMove;
