import React from "react";
import "../App.css";
import PageTransition from "../functions/pageTransition";

const GamePage = () => {
  let gameWon = false;
  const size = 20;
  let pieceCount = 0;

  const DetermineLocation = (index, size) => {
    const sep = (2 * Math.PI) / size;
    const radialLocation = sep * index;
    const xCoord = Math.sin(radialLocation);
    const yCoord = Math.cos(radialLocation);
    return { top: `${44 + 40 * xCoord}%`, left: `${44 + 40 * yCoord}%` };
  };

  let allPieces = [];
  const CreateBoard = (size) => {
    let gameBoard = [];
    for (let i = 0; i < size; i++) {
      gameBoard.push(
        <div
          className="Nim-Dot"
          onClick={(event) => GetClickedPiece(event)}
          key={`piece-${i}`}
          id={`piece-${i}`}
          style={DetermineLocation(i, size)}
        ></div>
      );
      allPieces.push(i);
    }
    return <div className="Game-Container">{gameBoard}</div>;
  };

  const GetClickedPiece = (event) => {
    if (event.currentTarget.classList.contains("Nim-Dot-Off")) {
      return;
    } else if (pieceCount < 3) {
      const clickedPiece = event.currentTarget;
      const pieceRemoved = clickedPiece.id.split("-")[1];
      RemovePiece(pieceRemoved);
      pieceCount++;
    } else {
      const messageBox = document.getElementsByClassName("Message-Box");
      messageBox[0].innerHTML = "Max Moves Taken, Please End Turn.";
    }
  };

  const RemovePiece = (piece) => {
    allPieces[piece] = "x";
    const pieceElement = document.getElementById(`piece-${piece}`);
    pieceElement.classList.add("Nim-Dot-Off");
  };

  let isComputerMoveInProgress = false;

  const ButtonAction = () => {
    if (allPieces.filter((x) => x !== "x").length === size) {
      const messageBox = document.getElementsByClassName("Message-Box");
      messageBox[0].innerHTML = "Human's go first";
      return;
    } else if (gameWon === false) {
      ComputerMove();
    } else {
      window.location.reload();
    }
  };

  const ComputerMove = () => {
    if (isComputerMoveInProgress) {
      return;
    }
    const messageBox = document.getElementsByClassName("Message-Box");
    messageBox[0].innerHTML = "Robo Turn";
    isComputerMoveInProgress = true;

    const activePieces = allPieces.filter((x) => x !== "x");
    const activePieceCount = activePieces.length;
    RemovePiece(activePieces[Math.floor(Math.random() * activePieces.length)]);

    isComputerMoveInProgress = false;
    const piecesToRemove = activePieceCount % 4;
    if (piecesToRemove > 1) {
      setTimeout(() => {
        ComputerMove();
      }, 750);
    } else {
      messageBox[0].innerHTML = "Your Turn";
      pieceCount = 0;
    }
    if (activePieceCount === 1) {
      gameWon = true;
      const messageBox = document.getElementsByClassName("Message-Box");
      messageBox[0].innerHTML = "Computer Wins. Play again?";
      const buttonText = document.getElementsByClassName("End-Turn-Button");
      buttonText[0].innerHTML = "Reset Game";
    }
  };

  return PageTransition(
    <div className="App">
      <header className="App-header">
        <div className="Message-Box">Welcome to Nim</div>
        {CreateBoard(size)}
        <button className="End-Turn-Button" onClick={ButtonAction}>
          End Turn
        </button>
      </header>
    </div>
  );
};

export default GamePage;
