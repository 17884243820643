import React from "react";
import "./App.css";
import HeadshotPage from "./pages/headshotPage";
import HeadshotStill from "./pages/headshotStill";
import GamePage from "./pages/gamePage";
import TicTacToe from "./pages/ticTacToe/ticTacToe";
import HomePage from "./pages/homePage";
import Nim from "./pages/nim";
import { Route, Routes } from "react-router-dom";
import BuildOrderPage from "./pages/buildSim/buildOrderPage";
import StoryGenPage from "./pages/storyGen/storyGenPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/face" element={<HeadshotPage />} />
      <Route path="/still" element={<HeadshotStill />} />
      <Route path="/game" element={<GamePage />} />
      <Route path="/nim" element={<Nim />} />
      <Route path="/tictactoe" element={<TicTacToe />} />
      <Route path="/build-order" element={<BuildOrderPage />} />
      <Route path="/story-gen" element={<StoryGenPage />} />
    </Routes>
  );
}

export default App;
