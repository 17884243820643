import Structure from "./Structure";

const hatchery = new Structure({
  name: "hatchery",
  armor: 1,
  maxHealth: 1500,
  buildTime: 71,
  mineralCost: 300,
  gasCost: 0,
  supplyProvided: 6,
  convertedFromUnit: "drone",
  race: "zerg",
});

const lair = new Structure({
  name: "lair",
  armor: 1,
  maxHealth: 2000,
  buildTime: 57,
  mineralCost: 150,
  gasCost: 100,
  supplyProvided: 6,
  convertedFromStructure: "hatchery",
  race: "zerg",
});

const hive = new Structure({
  name: "hive",
  armor: 1,
  maxHealth: 2500,
  buildTime: 71,
  mineralCost: 200,
  gasCost: 150,
  supplyProvided: 6,
  convertedFromStructure: "lair",
  requiredStructure: "infestationPit",
  race: "zerg",
});

const extractor = new Structure({
  name: "extractor",
  armor: 1,
  maxHealth: 500,
  buildTime: 21,
  mineralCost: 25,
  gasCost: 0,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  race: "zerg",
});

const spawningPool = new Structure({
  name: "spawningPool",
  armor: 1,
  maxHealth: 1000,
  buildTime: 46,
  mineralCost: 200,
  gasCost: 0,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  race: "zerg",
});

const evolutionChamber = new Structure({
  name: "evolutionChamber",
  armor: 1,
  maxHealth: 750,
  buildTime: 25,
  mineralCost: 75,
  gasCost: 0,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  race: "zerg",
});

const roachWarren = new Structure({
  name: "roachWarren",
  armor: 1,
  maxHealth: 850,
  buildTime: 39,
  mineralCost: 150,
  gasCost: 0,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "spawningPool",
  race: "zerg",
});

const banelingNest = new Structure({
  name: "banelingNest",
  armor: 1,
  maxHealth: 850,
  buildTime: 43,
  mineralCost: 100,
  gasCost: 50,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "spawningPool",
  race: "zerg",
});

const spineCrawler = new Structure({
  name: "spineCrawler",
  armor: 2,
  maxHealth: 300,
  buildTime: 36,
  mineralCost: 100,
  gasCost: 0,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  attackDamage: 25,
  attackRange: 7,
  attackSpeed: 1.32,
  attackType: "ranged",
  requiredStructure: "spawningPool",
  race: "zerg",
});

const sporeCrawler = new Structure({
  name: "sporeCrawler",
  armor: 1,
  maxHealth: 400,
  buildTime: 21,
  mineralCost: 75,
  gasCost: 0,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  attackDamage: 15,
  attackRange: 7,
  attackSpeed: 0.61,
  attackType: "ranged",
  requiredStructure: "spawningPool",
  race: "zerg",
});

const hydraliskDen = new Structure({
  name: "hydraliskDen",
  armor: 1,
  maxHealth: 850,
  buildTime: 29,
  mineralCost: 100,
  gasCost: 100,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "lair",
  race: "zerg",
});

const infestationPit = new Structure({
  name: "infestationPit",
  armor: 1,
  maxHealth: 850,
  buildTime: 36,
  mineralCost: 100,
  gasCost: 100,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "lair",
  race: "zerg",
});

const lurkerDen = new Structure({
  name: "lurkerDen",
  armor: 1,
  maxHealth: 850,
  buildTime: 57,
  mineralCost: 100,
  gasCost: 150,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "hydraliskDen",
  race: "zerg",
});

const spire = new Structure({
  name: "spire",
  armor: 1,
  maxHealth: 850,
  buildTime: 71,
  mineralCost: 200,
  gasCost: 200,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "lair",
  race: "zerg",
});

const nydusNetwork = new Structure({
  name: "nydusNetwork",
  armor: 1,
  maxHealth: 850,
  buildTime: 36,
  mineralCost: 150,
  gasCost: 150,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "lair",
  race: "zerg",
});

const ultraliskCavern = new Structure({
  name: "ultraliskCavern",
  armor: 1,
  maxHealth: 850,
  buildTime: 46,
  mineralCost: 150,
  gasCost: 200,
  supplyProvided: 0,
  convertedFromUnit: "drone",
  requiredStructure: "hive",
  race: "zerg",
});

const greaterSpire = new Structure({
  name: "greaterSpire",
  armor: 1,
  maxHealth: 1000,
  buildTime: 71,
  mineralCost: 100,
  gasCost: 150,
  supplyProvided: 0,
  convertedFromStructure: "spire",
  requiredStructure: "hive",
  race: "zerg",
});

const commandCenter = new Structure({
  name: "commandCenter",
  armor: 1,
  maxHealth: 1500,
  buildTime: 100,
  mineralCost: 400,
  gasCost: 0,
  supplyProvided: 15,
  race: "terran",
});
const refinery = new Structure({
  name: "refinery",
  armor: 1,
  maxHealth: 500,
  buildTime: 30,
  mineralCost: 75,
  gasCost: 0,
  supplyProvided: 0,
  race: "terran",
});
const supplyDepot = new Structure({
  name: "supplyDepot",
  armor: 1,
  maxHealth: 400,
  buildTime: 30,
  mineralCost: 100,
  gasCost: 0,
  supplyProvided: 8,
  race: "terran",
});
const engineeringBay = new Structure({
  name: "engineeringBay",
  armor: 1,
  maxHealth: 850,
  buildTime: 35,
  mineralCost: 125,
  gasCost: 0,
  supplyProvided: 0,
  race: "terran",
});
const barracks = new Structure({
  name: "barracks",
  armor: 1,
  maxHealth: 1000,
  buildTime: 40,
  mineralCost: 100,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "supplyDepot",
  race: "terran",
});
const missileTurret = new Structure({
  name: "missileTurret",
  armor: 0,
  maxHealth: 250,
  buildTime: 25,
  mineralCost: 100,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "engineeringBay",
  race: "terran",
  attackDamage: 12,
  attackRange: 7,
  attackSpeed: 0.86,
  attackType: "building",
});
const sensorTower = new Structure({
  name: "sensorTower",
  armor: 0,
  maxHealth: 200,
  buildTime: 25,
  mineralCost: 125,
  gasCost: 100,
  supplyProvided: 0,
  requiredStructure: "engineeringBay",
  race: "terran",
});
const bunker = new Structure({
  name: "bunker",
  armor: 1,
  maxHealth: 400,
  buildTime: 40,
  mineralCost: 100,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "barracks",
  race: "terran",
});
const orbitalCommand = new Structure({
  name: "orbitalCommand",
  armor: 1,
  maxHealth: 1500,
  buildTime: 35,
  mineralCost: 150,
  gasCost: 0,
  supplyProvided: 8,
  convertedFromStructure: "commandCenter",
  requiredStructure: "barracks",
  race: "terran",
});
const planetaryFortress = new Structure({
  name: "planetaryFortress",
  armor: 3,
  maxHealth: 1500,
  buildTime: 50,
  mineralCost: 150,
  gasCost: 150,
  supplyProvided: 8,
  convertedFromStructure: "commandCenter",
  requiredStructure: "engineeringBay",
  race: "terran",
  attackDamage: 40,
  attackRange: 6,
  attackSpeed: 2,
  attackType: "building",
});
const ghostAcademy = new Structure({
  name: "ghostAcademy",
  armor: 1,
  maxHealth: 1250,
  buildTime: 40,
  mineralCost: 150,
  gasCost: 50,
  supplyProvided: 0,
  requiredStructure: "barracks",
  race: "terran",
});
const factory = new Structure({
  name: "factory",
  armor: 1,
  maxHealth: 1250,
  buildTime: 60,
  mineralCost: 150,
  gasCost: 100,
  supplyProvided: 0,
  requiredStructure: "barracks",
  race: "terran",
});
const armory = new Structure({
  name: "armory",
  armor: 1,
  maxHealth: 750,
  buildTime: 65,
  mineralCost: 150,
  gasCost: 50,
  supplyProvided: 0,
  requiredStructure: "factory",
  race: "terran",
});
const starport = new Structure({
  name: "starport",
  armor: 1,
  maxHealth: 1300,
  buildTime: 50,
  mineralCost: 150,
  gasCost: 100,
  supplyProvided: 0,
  requiredStructure: "factory",
  race: "terran",
});
const fusionCore = new Structure({
  name: "fusionCore",
  armor: 1,
  maxHealth: 750,
  buildTime: 65,
  mineralCost: 150,
  gasCost: 150,
  supplyProvided: 0,
  requiredStructure: "starport",
  race: "terran",
});
const techLab = new Structure({
  name: "techLab",
  armor: 1,
  maxHealth: 400,
  buildTime: 50,
  mineralCost: 50,
  gasCost: 50,
  supplyProvided: 0,
  requiredStructure: "barracks",
  race: "terran",
});
const reactor = new Structure({
  name: "reactor",
  armor: 1,
  maxHealth: 400,
  buildTime: 25,
  mineralCost: 50,
  gasCost: 25,
  supplyProvided: 0,
  requiredStructure: "barracks",
  race: "terran",
});
const nexus = new Structure({
  name: "nexus",
  armor: 1,
  maxHealth: 1000,
  buildTime: 100,
  mineralCost: 400,
  gasCost: 0,
  supplyProvided: 15,
  race: "protoss",
});
const assimilator = new Structure({
  name: "assimilator",
  armor: 1,
  maxHealth: 300,
  buildTime: 30,
  mineralCost: 75,
  gasCost: 0,
  supplyProvided: 0,
  race: "protoss",
});
const pylon = new Structure({
  name: "pylon",
  armor: 1,
  maxHealth: 200,
  buildTime: 25,
  mineralCost: 100,
  gasCost: 0,
  supplyProvided: 8,
  race: "protoss",
});
const gateway = new Structure({
  name: "gateway",
  armor: 1,
  maxHealth: 500,
  buildTime: 65,
  mineralCost: 150,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "pylon",
  race: "protoss",
});
const forge = new Structure({
  name: "forge",
  armor: 1,
  maxHealth: 400,
  buildTime: 45,
  mineralCost: 150,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "pylon",
  race: "protoss",
});
const cyberneticsCore = new Structure({
  name: "cyberneticsCore",
  armor: 1,
  maxHealth: 550,
  buildTime: 50,
  mineralCost: 150,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "gateway",
  race: "protoss",
});
const photonCannon = new Structure({
  name: "photonCannon",
  armor: 1,
  maxHealth: 150,
  buildTime: 40,
  mineralCost: 150,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "forge",
  race: "protoss",
  attackDamage: 20,
  attackRange: 7,
  attackSpeed: 1.25,
  attackType: "building",
});
const shieldBattery = new Structure({
  name: "shieldBattery",
  armor: 1,
  maxHealth: 150,
  buildTime: 40,
  mineralCost: 100,
  gasCost: 0,
  supplyProvided: 0,
  requiredStructure: "cyberneticsCore",
  race: "protoss",
});
const twilightCouncil = new Structure({
  name: "twilightCouncil",
  armor: 1,
  maxHealth: 500,
  buildTime: 50,
  mineralCost: 150,
  gasCost: 100,
  supplyProvided: 0,
  requiredStructure: "cyberneticsCore",
  race: "protoss",
});
const stargate = new Structure({
  name: "stargate",
  armor: 1,
  maxHealth: 600,
  buildTime: 60,
  mineralCost: 150,
  gasCost: 150,
  supplyProvided: 0,
  requiredStructure: "cyberneticsCore",
  race: "protoss",
});
const roboticsFacility = new Structure({
  name: "roboticsFacility",
  armor: 1,
  maxHealth: 450,
  buildTime: 65,
  mineralCost: 150,
  gasCost: 100,
  supplyProvided: 0,
  requiredStructure: "cyberneticsCore",
  race: "protoss",
});
const templarArchives = new Structure({
  name: "templarArchives",
  armor: 1,
  maxHealth: 500,
  buildTime: 50,
  mineralCost: 150,
  gasCost: 200,
  supplyProvided: 0,
  requiredStructure: "twilightCouncil",
  race: "protoss",
});
const fleetBeacon = new Structure({
  name: "fleetBeacon",
  armor: 1,
  maxHealth: 500,
  buildTime: 60,
  mineralCost: 300,
  gasCost: 200,
  supplyProvided: 0,
  requiredStructure: "stargate",
  race: "protoss",
});
const roboticsBay = new Structure({
  name: "roboticsBay",
  armor: 1,
  maxHealth: 500,
  buildTime: 65,
  mineralCost: 150,
  gasCost: 150,
  supplyProvided: 0,
  requiredStructure: "roboticsFacility",
  race: "protoss",
});
const darkShrine = new Structure({
  name: "darkShrine",
  armor: 1,
  maxHealth: 500,
  buildTime: 100,
  mineralCost: 150,
  gasCost: 150,
  supplyProvided: 0,
  requiredStructure: "twilightCouncil",
  race: "protoss",
});

export default {
  hatchery,
  lair,
  hive,
  extractor,
  spawningPool,
  evolutionChamber,
  roachWarren,
  banelingNest,
  spineCrawler,
  sporeCrawler,
  hydraliskDen,
  infestationPit,
  lurkerDen,
  spire,
  nydusNetwork,
  ultraliskCavern,
  greaterSpire,
  commandCenter,
  refinery,
  supplyDepot,
  engineeringBay,
  barracks,
  missileTurret,
  sensorTower,
  bunker,
  orbitalCommand,
  planetaryFortress,
  ghostAcademy,
  factory,
  armory,
  starport,
  fusionCore,
  techLab,
  reactor,
  nexus,
  assimilator,
  pylon,
  gateway,
  forge,
  cyberneticsCore,
  photonCannon,
  shieldBattery,
  twilightCouncil,
  stargate,
  roboticsFacility,
  templarArchives,
  fleetBeacon,
  roboticsBay,
  darkShrine,
};
