import React from "react";
import headshot from "../headshot.jpg";
import "../App.css";
import { Link } from "react-router-dom";

const HeadshotPage = () => {
  return (
    <div className="App">
      <header className="App-header">
        <Link to="/build-order">
          <img
            src={headshot}
            className="Spinning-headshot"
            alt="spinning headshot fading between full color and black and white"
          />
        </Link>
      </header>
    </div>
  );
};

export default HeadshotPage;
