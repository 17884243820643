import lodash from "lodash";
import validateRequirements from "./validateRequirements";
import Structure from "./Structure";
import Unit from "./Unit";
import structureLookup from "./structureLookup";
import unitLookup from "./unitLookup";

const buildSim = (buildOrder, unitList, structureList) => {
  let tick = 0;
  let currentBuildIndex = 0;
  let currentlyBuilding = [];
  let mineralBank = 50;
  let gasBank = 0; // Gas needs to: 1. Check how many extractors there are; 2. Allocate some number of workers to that resource (max 3 per extractor)
  //Set up workers with counter for how long the worker is mining
  let workersInMinerals = 12;
  let currentUnitList = lodash.cloneDeep(unitList);
  let currentStructureList = lodash.cloneDeep(structureList);
  let currentWorkers = [];
  for (let i = 0; i < currentUnitList.length; i++) {
    if (currentUnitList[i]["type"] === "worker") {
      currentWorkers.push({
        unit: currentUnitList[i],
        task: "mining",
        taskCounter: 0,
      });
    }
  }

  while (
    !(currentBuildIndex === buildOrder.length && currentlyBuilding.length === 0)
  ) {
    if (currentBuildIndex < buildOrder.length) {
      if (buildOrder[currentBuildIndex]["mineralCost"] <= mineralBank) {
        if (buildOrder[currentBuildIndex]) {
          if (
            Object.values(
              validateRequirements(
                buildOrder[currentBuildIndex],
                currentStructureList,
                currentUnitList
              )
            ).every((value) => value === true)
          ) {
            if (buildOrder[currentBuildIndex]["convertedFromUnit"]) {
              for (
                let i = 0;
                i <
                (buildOrder[currentBuildIndex]["convertedFromUnitCount"] ?? 1);
                i++
              ) {
                let index = currentUnitList.findIndex(
                  (x) =>
                    x.name ===
                    buildOrder[currentBuildIndex]["convertedFromUnit"]
                );

                let workerIndex = 0;
                if (currentUnitList[index]["type"] === "worker") {
                  let lowestCounter = 2000;
                  for (let i = 0; i < currentWorkers.length; i++) {
                    if (currentWorkers[i]["taskCounter"] === 0) {
                      workerIndex = i;
                      break;
                    } else if (
                      currentWorkers[i]["taskCounter"] < lowestCounter
                    ) {
                      workerIndex = i;
                      lowestCounter = currentWorkers[i]["taskCounter"];
                    }
                  }
                }
                currentWorkers.splice(workerIndex, 1);
                currentUnitList.splice(index, 1);
              }
            }
            if (buildOrder[currentBuildIndex]["convertedFromStructure"]) {
              let index = currentStructureList.findIndex(
                (x) =>
                  x.name ===
                  buildOrder[currentBuildIndex]["convertedFromStructure"]
              );
              currentStructureList.splice(index, 1);
            }
            mineralBank -= buildOrder[currentBuildIndex]["mineralCost"];
            currentlyBuilding.push({
              item: buildOrder[currentBuildIndex],
              buildTickCount: 0,
              finishedBuilding: false,
            });
            currentBuildIndex++;
          }
        }
      }
    }
    for (let i = 0; i < currentlyBuilding.length; i++) {
      if (
        currentlyBuilding[i]["buildTickCount"] <=
        currentlyBuilding[i]["item"]["buildTime"] * 16
      ) {
        currentlyBuilding[i]["buildTickCount"]++;
      } else {
        currentlyBuilding[i]["finishedBuilding"] = true;
      }
    }
    for (let i = 0; i < currentlyBuilding.length; i++) {
      if (currentlyBuilding[i]["finishedBuilding"]) {
        if (currentlyBuilding[i]["item"] instanceof Unit) {
          currentUnitList.push(currentlyBuilding[i]["item"]);
          if (currentlyBuilding[i]["item"]["type"] === "worker") {
            currentWorkers.push({
              unit: currentlyBuilding[i]["item"],
              task: "mining",
              taskCounter: 0,
            });
          }
        } else {
          currentStructureList.push(currentlyBuilding[i]["item"]);
        }
      }
    }
    currentlyBuilding = currentlyBuilding.filter(
      (x) => x.finishedBuilding === false
    );

    for (let i = 0; i < currentWorkers.length; i++) {
      if (currentWorkers[i]["task"] === "mining") {
        console.log(i, currentWorkers[i]["taskCounter"], mineralBank);
        if (currentWorkers[i]["taskCounter"] >= 175) {
          mineralBank += 5;
          currentWorkers[i]["taskCounter"] = 0;
        } else {
          currentWorkers[i]["taskCounter"]++;
        }
        console.log(i, currentWorkers[i]["taskCounter"], mineralBank);
      }
    }
    // console.log(currentWorkers);
    // workersInMinerals = workerCount;
    // mineralBank += (workersInMinerals * 0.57) / 16; //Remove
    tick++;
  }
  const seconds = tick / 16;

  return seconds;
};

export default buildSim;
