const validateRequirements = (
  newItem,
  currentStructureList,
  currentUnitList
) => {
  let convertedFromStructureFound = true;
  let requiredStructureFound = true;
  let productionStructureFound = true;
  let unitFound = true;

  if (newItem.convertedFromStructure) {
    convertedFromStructureFound = currentStructureList.some(
      (structure) => structure.name === newItem.convertedFromStructure
    );
  }

  if (newItem.requiredStructure) {
    requiredStructureFound = currentStructureList.some(
      (structure) => structure.name === newItem.requiredStructure
    );
  }

  if (newItem.productionStructure) {
    productionStructureFound = currentStructureList.some(
      (structure) => structure.name === newItem.productionStructure
    );
  }

  if (newItem.convertedFromUnit) {
    unitFound = currentUnitList.some(
      (unit) => unit.name === newItem.convertedFromUnit
    );
  }

  return {
    convertedFromStructureFound,
    unitFound,
    requiredStructureFound,
    productionStructureFound,
  };
};

export default validateRequirements;
