class Structure {
  /**
   * @param {Object} options
   * @param {string} options.name
   * @param {number} options.armor
   * @param {number} options.maxHealth
   * @param {number} options.buildTime
   * @param {number} options.mineralCost
   * @param {number} options.gasCost
   * @param {number} options.supplyProvided
   * @param {string} options.convertedFromStructure
   * @param {string} options.convertedFromUnit
   * @param {string} options.requiredStructure
   * @param {string} options.race
   * @param {number} options.attackDamage
   * @param {number} options.attackRange
   * @param {number} options.attackSpeed
   * @param {string} options.attackType
   */

  constructor(options) {
    this.name = options.name;
    this.armor = options.armor;
    this.maxHealth = options.maxHealth;
    this.buildTime = options.buildTime;
    this.mineralCost = options.mineralCost;
    this.gasCost = options.gasCost;
    this.supplyProvided = options.supplyProvided;
    this.convertedFromStructure = options.convertedFromStructure;
    this.convertedFromUnit = options.convertedFromUnit;
    this.requiredStructure = options.requiredStructure;
    this.race = options.race;
    this.attackDamage = options.attackDamage;
    this.attackRange = options.attackRange;
    this.attackSpeed = options.attackSpeed;
    this.attackType = options.attackType;
  }
}

export default Structure;
