import React from "react";
import "../App.css";
import { Link } from "react-router-dom";

const linkStyle = {
  color: "black",
  border: "2px solid black",
  margin: "5px",
  padding: "8px 12px",
  textDecoration: "none",
  display: "block",
  borderRadius: "20px",
  transition: "background-color 0.3s ease",
};

const leftColumnStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "15px",
};

const HeaderBar = () => {
  return (
    <div>
      <header className="header-bar">
        <div style={leftColumnStyle}>
          <Link to="/build-order" style={linkStyle}>
            <b>Build Sim</b>
          </Link>
        </div>
        <h1>Thanks for stopping by!</h1>{" "}
        <div style={leftColumnStyle}>
          <Link to="/story-gen" style={linkStyle}>
            <b>Story Dice</b>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default HeaderBar;
