import React, { useState, useEffect } from "react";
import "../../App.css";
import PageTransition from "../../functions/pageTransition";
import HeaderBar from "../../components/headerBar";
import wordSearch from "./wordSearch";

const StoryGenPage = () => {
  const [chosenSetting, setSetting] = useState("");
  const [chosenCharacter, setCharacter] = useState("");
  const [chosenObject, setObject] = useState("");
  const [chosenAction, setAction] = useState("");
  const [chosenEmotion, setEmotion] = useState("");
  const [chosenDescriptor, setDescriptor] = useState("");

  const randomGenerate = () => {
    setSetting(wordSearch("setting"));
    setCharacter(wordSearch("character"));
    setObject(wordSearch("object"));
    setAction(wordSearch("action"));
    setEmotion(wordSearch("emotion"));
    setDescriptor(wordSearch("descriptor"));
  };

  return PageTransition(
    <div className="App">
      <HeaderBar />
      <header className="App-header">
        <table>
          <tr>
            <th style={{ padding: "0.5vmin" }}>Setting</th>
            <th>|</th>
            <th style={{ padding: "0.5vmin" }}>Character</th>
            <th>|</th>
            <th style={{ padding: "0.5vmin" }}>Object</th>
            <th>|</th>
            <th style={{ padding: "0.5vmin" }}>Action</th>
            <th>|</th>
            <th style={{ padding: "0.5vmin" }}>Emotion</th>
            <th>|</th>
            <th style={{ padding: "0.5vmin" }}>Descriptor</th>
            <th>
              <button onClick={randomGenerate}>
                <b>Roll the Dice</b>
              </button>
            </th>
          </tr>
          <tr>
            <td>{chosenSetting}</td>
            <td>|</td>
            <td>{chosenCharacter}</td>
            <td>|</td>
            <td>{chosenObject}</td>
            <td>|</td>
            <td>{chosenAction}</td>
            <td>|</td>
            <td>{chosenEmotion}</td>
            <td>|</td>
            <td>{chosenDescriptor}</td>
          </tr>
        </table>
      </header>
    </div>
  );
};

export default StoryGenPage;
