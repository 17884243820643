const GenerateMoveSet = (boardState) => {
  let convertedBoard = [];
  for (let i = 0; i < boardState.length; i++) {
    if (boardState[i] === "") {
      convertedBoard.push(i);
    } else {
      convertedBoard.push(boardState[i]);
    }
  }
  const filteredBoard = convertedBoard.filter((x) => typeof x === "number");

  let potentialMoves = {};
  for (const key of filteredBoard) {
    potentialMoves[key] = 1;
  }
  const moveObject = { boardState: boardState, potentialMoves: potentialMoves };
  return moveObject;
};

export default GenerateMoveSet;
