import "../App.css";
import { motion } from "framer-motion";

const PageTransition = (children) => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const pageTransition = {
    duration: 0.5,
  };
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
      className="App"
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
