const wordSearch = (table) => {
  const setting = [
    "Jungle",
    "Castle",
    "Space",
    "Desert",
    "Ocean",
    "Forest",
    "Mountain",
    "Mansion",
    "Village",
    "City",
    "Island",
    "Cave",
    "Ruins",
    "School",
    "Temple",
    "Circus",
    "Factory",
    "Park",
    "Graveyard",
    "Carnival",
    "Library",
    "Tundra",
    "Farm",
    "Lighthouse",
    "Palace",
    "Laboratory",
    "Tavern",
    "Castle",
    "Alley",
    "Dungeon",
    "Market",
    "Zoo",
    "Circus",
    "Swamp",
    "Oasis",
    "Theater",
    "Asylum",
    "Bar",
    "Stadium",
    "Quarry",
    "Volcano",
    "Lodge",
    "Ship",
    "Mine",
    "Asylum",
    "Gallery",
    "Prison",
    "Studio",
    "Office",
    "Diner",
    "Harbor",
    "Airport",
    "Mansion",
    "Stadium",
    "Observatory",
    "Workshop",
    "Canyon",
    "Dam",
    "Laboratory",
    "Arena",
    "Alley",
    "Tower",
    "Shrine",
    "Beach",
    "Oasis",
    "Cabin",
    "Farm",
    "Ranch",
    "Cafe",
    "Studio",
    "Campus",
    "Quarry",
    "Orchard",
    "Canal",
    "Depot",
    "Camp",
    "Club",
    "Lodge",
    "Mill",
    "Garage",
    "Vault",
    "Station",
    "Vault",
    "Lounge",
    "Arena",
    "Bunker",
    "Cathedral",
    "Mansion",
    "Resort",
    "Resort",
    "Manor",
    "Grotto",
    "Citadel",
    "Hut",
    "Cafe",
    "Pub",
    "Diner",
    "Fort",
    "Harbor",
    "Tower",
    "Haunted House",
    "Enchanted Forest",
    "Pirate Ship",
    "Space Station",
    "Medieval Castle",
    "Desert Oasis",
    "Ancient Ruins",
    "Underwater City",
    "Snowy Mountain",
    "Alien Planet",
    "Busy Marketplace",
    "Abandoned Warehouse",
    "Secret Laboratory",
    "Hidden Cave",
    "Tropical Island",
    "High School",
    "Spooky Graveyard",
    "Futuristic City",
    "Jungle Safari",
    "Deep Dungeon",
    "Seaside Village",
    "Fairytale Kingdom",
    "Time Machine",
    "Volcano Crater",
    "Circus Tent",
    "Magic Academy",
    "Ghost Town",
    "Dragon's Lair",
    "Wild West Town",
    "Submarine",
    "Moon Base",
    "Royal Palace",
    "Haunted Carnival",
    "Superhero Headquarters",
    "Viking Longship",
    "Enchanted Garden",
    "Ancient Library",
    "Urban Rooftop",
    "Safari Camp",
    "Archaeological Dig Site",
    "Steampunk City",
    "Lost Atlantis",
    "Meteor Crash Site",
    "Forest Clearing",
    "Ski Resort",
    "Space Shuttle",
    "Secret Hideout",
    "Fantasy Tavern",
    "Railway Station",
    "Vampire's Castle",
    "Floating Island",
    "Medieval Tournament",
    "Robot Factory",
    "Pirate Cove",
    "Starship Bridge",
    "Deserted Island",
    "Sunken Shipwreck",
    "Carnival Midway",
    "Magic Shop",
    "Astral Plane",
    "City Park",
    "Ice Palace",
    "Gnome Village",
    "Haunted Mansion",
    "Wizard's Tower",
    "Train Station",
    "Underworld",
    "Clockwork City",
    "Desert Bazaar",
    "Mountaintop Monastery",
    "Stormy Cliffside",
    "Zombie Apocalypse",
    "Enchanted Swamp",
    "Desert Canyon",
    "Ancient Pyramid",
    "Snowy Forest",
    "Secret Base",
    "Hidden Temple",
    "Future Metropolis",
    "Riverboat Casino",
    "Lighthouse",
    "Alchemist's Lab",
    "Opera House",
    "Sorcerer's Cave",
    "Giant's Castle",
    "Forest Village",
    "Fairy Glen",
    "Ocean Floor",
    "Crystal Cavern",
    "Enchanted Castle",
    "Ruined Cathedral",
    "Mountain Pass",
    "Hidden Valley",
    "Nomad Camp",
    "Post-Apocalyptic Wasteland",
    "Mystic River",
    "Deserted Cabin",
    "Space Dock",
    "Rock Concert",
    "Medieval Village",
  ];
  const character = [
    "Knight",
    "Scientist",
    "Detective",
    "Pirate",
    "Witch",
    "Cowboy",
    "Spy",
    "Astronaut",
    "Superhero",
    "Chef",
    "Wizard",
    "Explorer",
    "Musician",
    "Athlete",
    "Archaeologist",
    "Robot",
    "Doctor",
    "Vampire",
    "Mermaid",
    "Samurai",
    "Space Traveler",
    "Time Traveler",
    "Alien",
    "Fairy",
    "Secret Agent",
    "Sorcerer",
    "Thief",
    "Princess",
    "Spy",
    "Ghost",
    "Zombie",
    "Werewolf",
    "Monk",
    "Ninja",
    "Barbarian",
    "Queen",
    "King",
    "Street Performer",
    "Bounty Hunter",
    "Fisherman",
    "Farmer",
    "Pilot",
    "Mechanic",
    "Bartender",
    "Dancer",
    "Actor",
    "Teacher",
    "Painter",
    "Sculptor",
    "Photographer",
    "Journalist",
    "Historian",
    "Gamer",
    "Fortune Teller",
    "Street Vendor",
    "Chef",
    "Waitress",
    "CEO",
    "Gardener",
    "Librarian",
    "Park Ranger",
    "Firefighter",
    "Paramedic",
    "Soldier",
    "Lawyer",
    "Judge",
    "Politician",
    "Activist",
    "Athlete",
    "Referee",
    "Entertainer",
    "Magician",
    "Clown",
    "Juggler",
    "Mime",
    "Acrobat",
    "Bodyguard",
    "Guard",
    "Miner",
    "Engineer",
    "Inventor",
    "Blacksmith",
    "Carpenter",
    "Tailor",
    "Fashion Designer",
    "Model",
    "Actor",
    "Stunt Double",
    "Makeup Artist",
    "Film Director",
    "Screenwriter",
    "Composer",
    "DJ",
    "Singer",
    "Composer",
    "Street Artist",
    "Animator",
    "Graphic Designer",
    "Game Developer",
    "Hacker",
  ];
  const object = [
    "Key",
    "Telescope",
    "Compass",
    "Mirror",
    "Globe",
    "Pocket Watch",
    "Map",
    "Lantern",
    "Camera",
    "Book",
    "Quill",
    "Feather",
    "Crystal Ball",
    "Stopwatch",
    "Telescope",
    "Scroll",
    "Paintbrush",
    "Scepter",
    "Amulet",
    "Dagger",
    "Crown",
    "Ring",
    "Staff",
    "Bow",
    "Shield",
    "Sword",
    "Hat",
    "Gloves",
    "Cloak",
    "Boots",
    "Potion",
    "Wand",
    "Lantern",
    "Compass",
    "Magnifying Glass",
    "Rope",
    "Grappling Hook",
    "Backpack",
    "Canteen",
    "Binoculars",
    "Flashlight",
    "Compass",
    "Sledgehammer",
    "Hammer",
    "Axe",
    "Knife",
    "Rope",
    "Net",
    "Shovel",
    "Pickaxe",
    "Crowbar",
    "Chainsaw",
    "Drill",
    "Toolbox",
    "Ladder",
    "Gear",
    "Screwdriver",
    "Pliers",
    "Wrench",
    "Tape Measure",
    "Safety Helmet",
    "Work Gloves",
    "Hard Hat",
    "Toolbox",
    "Stapler",
    "Glue Gun",
    "Sewing Kit",
    "Paint Roller",
    "Spray Paint",
    "Paintbrush",
    "Palette",
    "Easel",
    "Canvas",
    "Sculpting Tools",
    "Pottery Wheel",
    "Clay",
    "Kiln",
    "Sketchbook",
    "Charcoal",
    "Easel",
    "Palette",
    "Paintbrush",
    "Canvas",
    "Sculpture",
    "Chisel",
    "Pottery Wheel",
    "Clay",
    "Glaze",
    "Kiln",
    "Modeling Clay",
    "Pottery Tools",
    "Needle and Thread",
    "Fabric",
    "Buttons",
    "Pins",
    "Scissors",
    "Sewing Machine",
    "Thimble",
    "Thread Spool",
    "Fabric Marker",
    "Coffee Mug",
    "Toothbrush",
    "Chair",
    "Lamp",
    "Fork",
    "Pen",
    "Notebook",
    "Pillow",
    "Clock",
    "Backpack",
    "Sunglasses",
    "Wallet",
    "Keychain",
    "Hat",
    "Shoes",
    "Belt",
    "Jacket",
    "Umbrella",
    "Wallet",
    "Scarf",
    "Gloves",
    "Socks",
    "Shirt",
    "Pants",
    "Dress",
    "Sweater",
    "Tie",
    "Bag",
    "T-shirt",
    "Shorts",
    "Sandals",
    "Phone",
    "Laptop",
    "Tablet",
    "Headphones",
    "Charger",
    "Remote Control",
    "Glasses",
    "Wristwatch",
    "Bracelet",
    "Necklace",
    "Earrings",
    "Ring",
    "Wallet",
    "Coin",
    "Wallet",
    "Bank Card",
    "ID Card",
    "Passport",
    "Driver's License",
    "Board Game",
    "Playing Cards",
    "Dice",
    "Puzzle",
    "Rubik's Cube",
    "Chess Set",
    "Checkers Set",
    "Snacks",
    "Water Bottle",
    "Tupperware",
    "Cutlery Set",
    "Plate",
    "Bowl",
    "Cup",
    "Napkin",
    "Tissue Box",
    "Soap",
    "Shampoo",
    "Conditioner",
    "Toothpaste",
    "Floss",
    "Mouthwash",
    "Hand Sanitizer",
    "Lotion",
    "Tissues",
    "Perfume",
    "Deodorant",
    "Razor",
    "Shaving Cream",
    "Hairbrush",
    "Comb",
    "Hair Tie",
    "Bobby Pins",
    "Hair Dryer",
    "Curling Iron",
    "Flat Iron",
    "Nail Clippers",
    "Nail File",
    "Tweezers",
    "Cotton Swabs",
    "First Aid Kit",
    "Bandages",
    "Antiseptic",
    "Thermometer",
    "Pain Reliever",
    "Allergy Medication",
    "Cough Drops",
    "Sunscreen",
    "Insect Repellent",
    "Hand Cream",
  ];
  const action = [
    "Running",
    "Jumping",
    "Swimming",
    "Climbing",
    "Dancing",
    "Singing",
    "Laughing",
    "Crying",
    "Sleeping",
    "Eating",
    "Drinking",
    "Talking",
    "Listening",
    "Reading",
    "Writing",
    "Drawing",
    "Painting",
    "Cooking",
    "Cleaning",
    "Walking",
    "Hiking",
    "Biking",
    "Driving",
    "Flying",
    "Riding",
    "Rowing",
    "Sailing",
    "Skating",
    "Skiing",
    "Snowboarding",
    "Surfing",
    "Skateboarding",
    "Rollerblading",
    "Jogging",
    "Exercising",
    "Stretching",
    "Meditating",
    "Yoga",
    "Pilates",
    "Weightlifting",
    "Pushing",
    "Pulling",
    "Lifting",
    "Carrying",
    "Pushing",
    "Rolling",
    "Tumbling",
    "Dodging",
    "Diving",
    "Leaping",
    "Ducking",
    "Weaving",
    "Parrying",
    "Fencing",
    "Sparring",
    "Wrestling",
    "Grappling",
    "Boxing",
    "Punching",
    "Kicking",
    "Blocking",
    "Counterattacking",
    "Defending",
    "Attacking",
    "Swinging",
    "Throwing",
    "Catching",
    "Juggling",
    "Balancing",
    "Flipping",
    "Tumbling",
    "Sliding",
    "Gliding",
    "Spinning",
    "Twisting",
    "Flailing",
    "Stumbling",
    "Tripping",
    "Falling",
    "Landing",
    "Rolling",
    "Recovering",
    "Regaining balance",
    "Colliding",
    "Charging",
    "Retreating",
    "Sneaking",
    "Peeking",
    "Hiding",
    "Sneaking",
    "Peeking",
    "Hiding",
    "Slipping",
    "Tripping",
    "Falling",
    "Tumbling",
    "Dodging",
    "Parrying",
    "Blocking",
    "Recovering",
    "Teleportation",
    "Time Traveling",
    "Mind Control",
    "Shape-shifting",
    "Elemental Manipulation",
    "Telekinesis",
    "Energy Projection",
    "Force Field Generation",
    "Molecular Reconstruction",
    "Invisibility",
    "Astral Projection",
    "Dimensional Rift Opening",
    "Illusion Casting",
    "Gravity Manipulation",
    "Psychic Abilities",
    "Cyberspace Hacking",
    "Nanobot Swarming",
    "Genetic Engineering",
    "Alien Technology Operation",
    "Cloaking",
    "Force Push",
    "Force Pull",
    "Force Jump",
    "Force Lightning",
    "Force Choke",
    "Energy Sword Combat",
    "Lightsaber Dueling",
    "Plasma Blasting",
    "Quantum Entanglement Communication",
    "Warp Drive Piloting",
    "Hyperjump Navigation",
    "Laser Gun Firing",
    "Spaceship Dogfighting",
    "Starship Sabotage",
    "Alien Encounter Negotiation",
    "Android Programming",
    "Virtual Reality Gaming",
    "Holodeck Simulation",
    "Artificial Intelligence Interaction",
    "Teleportation Disruption",
    "Mind Reading",
    "Precognition",
    "Levitation",
    "Teleportation",
    "Elemental Conjuring",
    "Time Manipulation",
    "Reality Warping",
    "Astral Projection",
    "Illusion Casting",
    "Dreamwalking",
    "Shape-shifting",
    "Flight",
    "Energy Absorption",
    "Telepathy",
    "Empathy",
    "Force Field Creation",
    "Molecular Manipulation",
    "Teleportation",
    "Time Travel",
    "Dimensional Travel",
    "Energy Blasting",
    "Super Strength",
    "Super Speed",
    "Invisibility",
    "Healing",
    "Regeneration",
    "Teleportation",
    "Mind Control",
    "Shape-shifting",
    "Elemental Control",
    "Technomancy",
    "Bioengineering",
    "Robotics",
    "Nanotechnology",
    "Cybernetic Enhancements",
    "Psychic Combat",
    "Space-time Distortion",
    "Galactic Warfare",
    "Alien Invasion",
    "Planetary Colonization",
    "Interstellar Diplomacy",
    "Galactic Exploration",
    "Wormhole Creation",
    "Multiverse Travel",
    "Quantum Computing",
    "Genetic Modification",
    "Transhumanism",
    "Alien Artifact Retrieval",
    "Planetary Defense",
    "Biohacking",
    "Quantum Teleportation",
    "Dark Matter Manipulation",
    "Antimatter Weaponry",
    "Psychic Warfare",
    "Cybernetic Augmentation",
    "Interdimensional Travel",
    "Temporal Loop Manipulation",
    "Holographic Projection",
    "Psychic Beacon Creation",
    "Reality Distortion",
  ];
  const emotion = [
    "Joy",
    "Sadness",
    "Anger",
    "Fear",
    "Surprise",
    "Disgust",
    "Excitement",
    "Love",
    "Hatred",
    "Envy",
    "Jealousy",
    "Guilt",
    "Shame",
    "Pride",
    "Empathy",
    "Compassion",
    "Sympathy",
    "Trust",
    "Distrust",
    "Optimism",
    "Pessimism",
    "Hope",
    "Despair",
    "Contentment",
    "Boredom",
    "Anxiety",
    "Stress",
    "Relief",
    "Anticipation",
    "Curiosity",
    "Amusement",
    "Awe",
    "Confusion",
    "Disbelief",
    "Wonder",
    "Frustration",
    "Irritation",
    "Impatience",
    "Contempt",
    "Agitation",
    "Restlessness",
    "Loneliness",
    "Isolation",
    "Longing",
    "Regret",
    "Remorse",
    "Satisfaction",
    "Disappointment",
    "Discontent",
    "Indifference",
    "Empowerment",
    "Vulnerability",
    "Resilience",
    "Disconnection",
    "Belonging",
    "Alienation",
    "Relief",
    "Devastation",
    "Astonishment",
    "Fright",
    "Elation",
    "Grief",
    "Panic",
    "Alarm",
    "Trepidation",
    "Hesitance",
    "Bravery",
    "Cowardice",
    "Disdain",
    "Revulsion",
    "Eagerness",
    "Lethargy",
    "Zeal",
    "Enthusiasm",
    "Dejection",
    "Hopelessness",
    "Weariness",
    "Liveliness",
    "Drowsiness",
    "Amazement",
    "Annoyance",
    "Dread",
    "Satisfaction",
    "Embarrassment",
    "Determination",
    "Defeat",
    "Resignation",
    "Fierceness",
    "Sympathy",
    "Empathy",
    "Compassion",
    "Apathy",
    "Devotion",
    "Dismay",
    "Surprise",
    "Enchantment",
    "Exhilaration",
    "Ferocity",
    "Delight",
    "Hysteria",
  ];
  const descriptor = [
    "Agile",
    "Bold",
    "Cautious",
    "Daring",
    "Energetic",
    "Fearless",
    "Gracious",
    "Humble",
    "Intuitive",
    "Joyful",
    "Kindhearted",
    "Loyal",
    "Modest",
    "Noble",
    "Optimistic",
    "Patient",
    "Quiet",
    "Resourceful",
    "Spirited",
    "Thoughtful",
    "Understanding",
    "Virtuous",
    "Wise",
    "Zealous",
    "Ambitious",
    "Brilliant",
    "Clever",
    "Determined",
    "Enthusiastic",
    "Fierce",
    "Generous",
    "Honest",
    "Independent",
    "Jovial",
    "Keen",
    "Loving",
    "Magnificent",
    "Natural",
    "Open-minded",
    "Passionate",
    "Reliable",
    "Strong",
    "Trustworthy",
    "Unique",
    "Vigilant",
    "Witty",
    "Adventurous",
    "Brave",
    "Charismatic",
    "Discerning",
    "Empathetic",
    "Flexible",
    "Grateful",
    "Imaginative",
    "Joyous",
    "Knowledgeable",
    "Leaderly",
    "Merciful",
    "Nurturing",
    "Observant",
    "Playful",
    "Quirky",
    "Resilient",
    "Selfless",
    "Tenacious",
    "Unassuming",
    "Valiant",
    "Wise",
    "Xenial",
    "Youthful",
    "Zestful",
    "Accomplished",
    "Brilliant",
    "Charming",
    "Dynamic",
    "Empowered",
    "Fearless",
    "Gifted",
    "Harmonious",
    "Ingenious",
    "Jubilant",
    "Kinetic",
    "Limitless",
    "Masterful",
    "Noble",
    "Optimistic",
    "Persuasive",
    "Quick-witted",
    "Radiant",
    "Serene",
    "Talented",
    "Unstoppable",
    "Victorious",
    "Whimsical",
    "Xanthic (yellow)",
    "Youthful",
    "Zany",
    "Ambitious",
    "Bubbly",
    "Compassionate",
  ];

  let searchTable;
  switch (table) {
    case "setting":
      searchTable = setting;
      break;
    case "character":
      searchTable = character;
      break;
    case "object":
      searchTable = object;
      break;
    case "action":
      searchTable = action;
      break;
    case "emotion":
      searchTable = emotion;
      break;
    case "descriptor":
      searchTable = descriptor;
      break;
  }

  const chosenWord =
    searchTable[Math.floor(Math.random() * searchTable.length)];

  return chosenWord;
};

export default wordSearch;
