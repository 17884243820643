import React from "react";
import "../App.css";
import HeaderBar from "../components/headerBar";
import starcraftLogoTransparent from "../components/starcraftLogoTransparent.png";
import journal from "../components/journal.jpg";

const Feature = ({ image, title, children }) => (
  <div className="feature">
    <div className="feature-image">
      <img src={image} alt={`${title} logo`} />
    </div>
    <div className="feature-content">
      <h2>{title}</h2>
      {children}
    </div>
  </div>
);

const HomePage = () => {
  return (
    <div className="App">
      <HeaderBar />
      <div className="body-home">
        <Feature image={starcraftLogoTransparent} title="Build Simulator (WIP)">
          <p>
            Take the SC2 Spreadsheet to the next level! Set your ideal build
            order<span className="small-text">*</span> and see what a frame
            perfect execution would look like, considering:
          </p>
          <ul className="feature-list">
            <li>Worker/Resource Allocation</li>
            <li>Simultaneous Building</li>
            <li>Production Slots</li>
            <li>Tech Requirements</li>
          </ul>
          <p>
            By understanding the execution time of a build, not only can you
            benchmark your time against a standard, you can gain greater
            understanding of the fundamentals of build order and basic macro
            timings. Now let the chaos and flexibility of the Ladder begin!
          </p>
          <p className="small-text">
            *warranty is voided in instances of cannon rushes
          </p>
        </Feature>
        <Feature image={journal} title="Story Dice (WIP)">
          <p>
            Running into a creative block? Looking for a way to improve your
            writing skills? Throw some randomness in the mix!
          </p>
          <p>
            Story Dice are a tool to create a random writing prompt using key
            components of the story. Setting these components on your writing
            helps provide a starting point to get around writer’s block, and
            provides restrictions that force you to work creatively to
            incorporate them into the story.
          </p>
          <p>Roll the dice and see where they take you!</p>
        </Feature>
      </div>
    </div>
  );
};

export default HomePage;
