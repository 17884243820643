import React from "react";
import "../App.css";

const tableComponent = (itemList, title) => {
  return (
    <table>
      <tr>
        <th>{title}</th>
      </tr>
      {itemList.map((item, index) => (
        <tr>
          <td>{item.name}</td>
        </tr>
      ))}
    </table>
  );
};

export default tableComponent;
