import Unit from "./Unit";

const drone = new Unit({
  name: "drone",
  type: "worker",
  speed: 2.81,
  attack: 5,
  attackType: "melee",
  attackRange: 1,
  attackSpeed: 1.5,
  armor: 0,
  armorType: "ground",
  baseHealth: 40,
  race: "zerg",
  buildTime: 17,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 0,
  creepEffect: "none",
});

const overlord = new Unit({
  name: "overlord",
  type: "other",
  speed: 0.64,
  attack: 0,
  armor: 0,
  armorType: "flyer",
  baseHealth: 200,
  race: "zerg",
  buildTime: 25,
  supplyCost: 0,
  supplyProvided: 8,
  mineralCost: 100,
  gasCost: 0,
});

const queen = new Unit({
  name: "queen",
  type: "other",
  speed: 0.94,
  attack: 9,
  attackType: "ranged",
  attackRange: 7,
  attackSpeed: 1,
  armor: 1,
  armorType: "ground",
  baseHealth: 175,
  race: "zerg",
  buildTime: 50,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 0,
  creepEffect: "increase",
  maxEnergy: 200,
  requiredStructure: "hatchery", //Figure out multiple types
  productionStructure: "hatchery",
});

const zergling = new Unit({
  name: "zergling",
  type: "army",
  speed: 2.95,
  attack: 5,
  attackType: "melee",
  attackRange: 1,
  attackSpeed: 0.7,
  armor: 0,
  armorType: "ground",
  baseHealth: 35,
  race: "zerg",
  buildTime: 24,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 0,
  creepEffect: "increase",
  requiredStructure: "spawningPool",
});

const roach = new Unit({
  name: "roach",
  type: "army",
  speed: 2.25,
  attack: 16,
  attackType: "ranged",
  attackRange: 4,
  attackSpeed: 2,
  armor: 1,
  armorType: "ground",
  baseHealth: 145,
  race: "zerg",
  buildTime: 27,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 75,
  gasCost: 25,
  creepEffect: "increase",
  requiredStructure: "roachWarren",
});

const hydralisk = new Unit({
  name: "hydralisk",
  type: "army",
  speed: 2.25,
  attack: 12,
  attackType: "ranged",
  attackRange: 5,
  attackSpeed: 0.82,
  armor: 0,
  armorType: "ground",
  baseHealth: 90,
  race: "zerg",
  buildTime: 33,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 50,
  creepEffect: "increase",
  requiredStructure: "hydraliskDen",
});

const mutalisk = new Unit({
  name: "mutalisk",
  type: "army",
  speed: 4,
  attack: 9,
  attackType: "air",
  attackRange: 3,
  attackSpeed: 1.52,
  armor: 0,
  armorType: "air",
  baseHealth: 120,
  race: "zerg",
  buildTime: 33,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 100,
  requiredStructure: "spire",
});

const corruptor = new Unit({
  name: "corruptor",
  type: "army",
  speed: 3.38,
  attack: 14,
  attackType: "air",
  attackRange: 6,
  attackSpeed: 1.9,
  armor: 2,
  armorType: "air",
  baseHealth: 200,
  race: "zerg",
  buildTime: 40,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 100,
  requiredStructure: "spire",
});

const infestor = new Unit({
  name: "infestor",
  type: "army",
  speed: 2.25,
  armor: 0,
  armorType: "ground",
  baseHealth: 90,
  race: "zerg",
  buildTime: 50,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 150,
  creepEffect: "increase",
  maxEnergy: 200,
  requiredStructure: "infestationPit",
});

const swarmHost = new Unit({
  name: "swarmHost",
  type: "army",
  speed: 2.25,
  armor: 1,
  armorType: "ground",
  baseHealth: 160,
  race: "zerg",
  buildTime: 50,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 75,
  creepEffect: "increase",
  requiredStructure: "infestationPit",
});

const locust = new Unit({
  name: "locust",
  type: "army",
  speed: 1.88,
  attack: 10,
  attackType: "ranged",
  attackRange: 3,
  attackSpeed: 0.6,
  armor: 1,
  armorType: "ground",
  baseHealth: 50,
  race: "zerg",
  creepEffect: "increase",
});

const viper = new Unit({
  name: "viper",
  type: "army",
  speed: 2.95,
  armor: 1,
  armorType: "air",
  baseHealth: 150,
  race: "zerg",
  buildTime: 40,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 200,
  maxEnergy: 200,
  requiredStructure: "hive",
});

const lurker = new Unit({
  name: "lurker",
  type: "army",
  speed: 2.95,
  attack: 20,
  attackType: "ranged",
  attackRange: 8,
  attackSpeed: 2,
  armor: 1,
  armorType: "ground",
  baseHealth: 200,
  race: "zerg",
  buildTime: 25,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 100,
  creepEffect: "increase",
  convertedFromUnit: "hydralisk",
  convertedFromUnitCount: 1,
  requiredStructure: "lurkerDen",
});

const ultralisk = new Unit({
  name: "ultralisk",
  type: "army",
  speed: 2.95,
  attack: 35,
  attackType: "melee",
  attackRange: 1,
  attackSpeed: 0.86,
  armor: 2,
  armorType: "ground",
  baseHealth: 500,
  race: "zerg",
  buildTime: 55,
  supplyCost: 6,
  supplyProvided: 0,
  mineralCost: 275,
  gasCost: 200,
  creepEffect: "increase",
  requiredStructure: "ultraliskCavern",
});

const ravager = new Unit({
  name: "ravager",
  type: "army",
  speed: 2.75,
  attack: 16,
  attackType: "ranged",
  attackRange: 6,
  attackSpeed: 1.6,
  armor: 1,
  armorType: "ground",
  baseHealth: 120,
  race: "zerg",
  buildTime: 17,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 25,
  gasCost: 75,
  creepEffect: "increase",
  convertedFromUnit: "roach",
  convertedFromUnitCount: 1,
});

const baneling = new Unit({
  name: "baneling",
  type: "army",
  speed: 2.5,
  attack: 16,
  attackType: "melee",
  attackRange: 1,
  attackSpeed: 0.83,
  armor: 0,
  armorType: "ground",
  baseHealth: 30,
  race: "zerg",
  buildTime: 20,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 50,
  creepEffect: "increase",
  convertedFromUnit: "zergling",
  convertedFromUnitCount: 1,
  requiredStructure: "banelingNest",
});

const overseer = new Unit({
  name: "overseer",
  type: "other",
  speed: 2.62,
  armor: 1,
  armorType: "air",
  baseHealth: 200,
  race: "zerg",
  buildTime: 12,
  supplyCost: 0,
  supplyProvided: 8,
  mineralCost: 50,
  gasCost: 50,
  maxEnergy: 200,
  convertedFromUnit: "overlord",
  convertedFromUnitCount: 1,
  requiredStructure: "lair",
});

const broodLord = new Unit({
  name: "broodLord",
  type: "army",
  speed: 2.62,
  attack: 20,
  attackType: "air",
  attackRange: 10,
  attackSpeed: 1.79,
  armor: 2,
  armorType: "air",
  baseHealth: 225,
  race: "zerg",
  buildTime: 24,
  supplyCost: 4,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 150,
  convertedFromUnit: "corruptor",
  convertedFromUnitCount: 1,
  requiredStructure: "greaterSpire",
});

const broodling = new Unit({
  name: "broodling",
  type: "army",
  speed: 4.13,
  attack: 4,
  attackType: "melee",
  attackRange: 1,
  attackSpeed: 0.57,
  armor: 0,
  armorType: "ground",
  baseHealth: 20,
  race: "zerg",
});

const scv = new Unit({
  name: "scv",
  type: "worker",
  speed: 2.81,
  attack: 5,
  attackType: "ground",
  attackRange: 1,
  attackSpeed: 1.5,
  armor: 0,
  armorType: "ground",
  baseHealth: 45,
  race: "terran",
  buildTime: 17,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 0,
  creepEffect: "slow",
  productionStructure: "commandCenter",
  techLabRequired: "FALSE",
});
const marine = new Unit({
  name: "marine",
  type: "army",
  speed: 2.25,
  attack: 6,
  attackType: "ground",
  attackRange: 5,
  attackSpeed: 0.86,
  armor: 0,
  armorType: "ground",
  baseHealth: 45,
  race: "terran",
  buildTime: 25,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 0,
  creepEffect: "slow",
  productionStructure: "barracks",
  techLabRequired: "FALSE",
});
const reaper = new Unit({
  name: "reaper",
  type: "army",
  speed: 3.75,
  attack: 4,
  attackType: "ground",
  attackRange: 5,
  attackSpeed: 1.1,
  armor: 0,
  armorType: "ground",
  baseHealth: 60,
  race: "terran",
  buildTime: 45,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 50,
  creepEffect: "slow",
  productionStructure: "barracks",
  techLabRequired: "FALSE",
});
const marauder = new Unit({
  name: "marauder",
  type: "army",
  speed: 2.25,
  attack: 10,
  attackType: "ground",
  attackRange: 6,
  attackSpeed: 1.5,
  armor: 1,
  armorType: "ground",
  baseHealth: 125,
  race: "terran",
  buildTime: 30,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 25,
  creepEffect: "slow",
  productionStructure: "barracks",
  techLabRequired: "TRUE",
});
const ghost = new Unit({
  name: "ghost",
  type: "army",
  speed: 2.81,
  attack: 10,
  attackType: "ground",
  attackRange: 6,
  attackSpeed: 1.5,
  armor: 0,
  armorType: "ground",
  baseHealth: 100,
  race: "terran",
  buildTime: 40,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 125,
  creepEffect: "slow",
  requiredStructure: "ghostAcademy",
  productionStructure: "barracks",
  techLabRequired: "TRUE",
});
const hellion = new Unit({
  name: "hellion",
  type: "army",
  speed: 4.25,
  attack: 8,
  attackType: "armor",
  attackRange: 5,
  attackSpeed: 2.5,
  armor: 0,
  armorType: "armor",
  baseHealth: 90,
  race: "terran",
  buildTime: 30,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 0,
  creepEffect: "slow",
  productionStructure: "armory",
  techLabRequired: "FALSE",
});
const widowMine = new Unit({
  name: "widowMine",
  type: "army",
  speed: 0,
  attackType: "armor",
  armor: 0,
  armorType: "armor",
  baseHealth: 90,
  race: "terran",
  buildTime: 30,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 75,
  gasCost: 25,
  creepEffect: "slow",
  productionStructure: "armory",
  techLabRequired: "FALSE",
});
const cyclone = new Unit({
  name: "cyclone",
  type: "army",
  speed: 2.81,
  attack: 11,
  attackType: "armor",
  attackRange: 6,
  attackSpeed: 0.81,
  armor: 0,
  armorType: "armor",
  baseHealth: 130,
  race: "terran",
  buildTime: 45,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 125,
  gasCost: 50,
  creepEffect: "slow",
  productionStructure: "armory",
  techLabRequired: "FALSE",
});
const siegeTank = new Unit({
  name: "siegeTank",
  type: "army",
  speed: 2.25,
  attack: 15,
  attackType: "armor",
  attackRange: 7,
  attackSpeed: 1.04,
  armor: 1,
  armorType: "armor",
  baseHealth: 175,
  race: "terran",
  buildTime: 45,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 125,
  creepEffect: "slow",
  productionStructure: "armory",
  techLabRequired: "TRUE",
});
const hellbat = new Unit({
  name: "hellbat",
  type: "army",
  speed: 2.25,
  attack: 18,
  attackType: "armor",
  attackRange: 2,
  attackSpeed: 2,
  armor: 0,
  armorType: "armor",
  baseHealth: 135,
  race: "terran",
  buildTime: 30,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 0,
  creepEffect: "slow",
  requiredStructure: "armory",
  productionStructure: "armory",
  techLabRequired: "TRUE",
});
const thor = new Unit({
  name: "thor",
  type: "army",
  speed: 1.88,
  attack: 6,
  attackType: "armor",
  attackRange: 10,
  attackSpeed: 3,
  armor: 1,
  armorType: "armor",
  baseHealth: 400,
  race: "terran",
  buildTime: 60,
  supplyCost: 6,
  supplyProvided: 0,
  mineralCost: 300,
  gasCost: 200,
  creepEffect: "slow",
  requiredStructure: "armory",
  productionStructure: "armory",
  techLabRequired: "TRUE",
});
const viking = new Unit({
  name: "viking",
  type: "army",
  speed: 2.75,
  attack: 10,
  attackType: "air",
  attackRange: 9,
  attackSpeed: 2,
  armor: 0,
  armorType: "air",
  baseHealth: 135,
  race: "terran",
  buildTime: 42,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 75,
  productionStructure: "starport",
  techLabRequired: "FALSE",
});
const medivac = new Unit({
  name: "medivac",
  type: "army",
  speed: 2.5,
  attackType: "air",
  armor: 1,
  armorType: "air",
  baseHealth: 150,
  race: "terran",
  buildTime: 42,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 100,
  maxEnergy: 200,
  productionStructure: "starport",
  techLabRequired: "FALSE",
});
const liberator = new Unit({
  name: "liberator",
  type: "army",
  speed: 3.38,
  attack: 5,
  attackType: "air",
  attackRange: 5,
  attackSpeed: 1.8,
  armor: 0,
  armorType: "air",
  baseHealth: 180,
  race: "terran",
  buildTime: 60,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 125,
  productionStructure: "starport",
  techLabRequired: "FALSE",
});
const raven = new Unit({
  name: "raven",
  type: "army",
  speed: 2.95,
  attackType: "air",
  armor: 1,
  armorType: "air",
  baseHealth: 140,
  race: "terran",
  buildTime: 48,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 150,
  maxEnergy: 200,
  productionStructure: "starport",
  techLabRequired: "TRUE",
});
const banshee = new Unit({
  name: "banshee",
  type: "army",
  speed: 2.75,
  attack: 12,
  attackType: "air",
  attackRange: 6,
  attackSpeed: 1.25,
  armor: 0,
  armorType: "air",
  baseHealth: 140,
  race: "terran",
  buildTime: 60,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 100,
  productionStructure: "starport",
  techLabRequired: "TRUE",
});
const battlecruiser = new Unit({
  name: "battlecruiser",
  type: "army",
  speed: 1.88,
  attack: 8,
  attackType: "air",
  attackRange: 6,
  attackSpeed: 0.23,
  armor: 3,
  armorType: "air",
  baseHealth: 550,
  race: "terran",
  buildTime: 90,
  supplyCost: 6,
  supplyProvided: 0,
  mineralCost: 400,
  gasCost: 300,
  requiredStructure: "fusionCore",
  productionStructure: "starport",
  techLabRequired: "TRUE",
});
const probe = new Unit({
  name: "probe",
  type: "worker",
  speed: 2.81,
  attack: 5,
  attackType: "ground",
  attackRange: 1,
  attackSpeed: 1.5,
  armor: 0,
  armorType: "ground",
  baseHealth: 20,
  race: "protoss",
  buildTime: 16,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 0,
  creepEffect: "slow",
  productionStructure: "nexus",
});
const zealot = new Unit({
  name: "zealot",
  type: "army",
  speed: 2.25,
  attack: 8,
  attackType: "ground",
  attackRange: 1,
  attackSpeed: 1.2,
  armor: 1,
  armorType: "ground",
  baseHealth: 50,
  race: "protoss",
  buildTime: 16,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 0,
  creepEffect: "slow",
  productionStructure: "gateway",
});
const sentry = new Unit({
  name: "sentry",
  type: "army",
  speed: 2.5,
  attack: 6,
  attackType: "ground",
  attackRange: 5,
  attackSpeed: 1,
  armor: 1,
  armorType: "ground",
  baseHealth: 40,
  race: "protoss",
  buildTime: 16,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 100,
  creepEffect: "slow",
  maxEnergy: 200,
  requiredStructure: "cyberneticsCore",
  productionStructure: "gateway",
});
const stalker = new Unit({
  name: "stalker",
  type: "army",
  speed: 2.95,
  attack: 13,
  attackType: "ground",
  attackRange: 6,
  attackSpeed: 1.87,
  armor: 1,
  armorType: "ground",
  baseHealth: 80,
  race: "protoss",
  buildTime: 16,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 125,
  gasCost: 50,
  creepEffect: "slow",
  requiredStructure: "cyberneticsCore",
  productionStructure: "gateway",
});
const adept = new Unit({
  name: "adept",
  type: "army",
  speed: 2.5,
  attack: 10,
  attackType: "ground",
  attackRange: 4,
  attackSpeed: 2.25,
  armor: 1,
  armorType: "ground",
  baseHealth: 70,
  race: "protoss",
  buildTime: 16,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 100,
  gasCost: 25,
  creepEffect: "slow",
  requiredStructure: "cyberneticsCore",
  productionStructure: "gateway",
});
const highTemplar = new Unit({
  name: "highTemplar",
  type: "army",
  speed: 2.02,
  attack: 4,
  attackType: "ground",
  attackRange: 6,
  attackSpeed: 1.75,
  armor: 0,
  armorType: "ground",
  baseHealth: 40,
  race: "protoss",
  buildTime: 16,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 50,
  gasCost: 150,
  creepEffect: "slow",
  maxEnergy: 200,
  requiredStructure: "templarArchives",
  productionStructure: "gateway",
});
const darkTemplar = new Unit({
  name: "darkTemplar",
  type: "army",
  speed: 2.81,
  attack: 45,
  attackType: "ground",
  attackRange: 1,
  attackSpeed: 1.69,
  armor: 1,
  armorType: "ground",
  baseHealth: 80,
  race: "protoss",
  buildTime: 16,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 125,
  gasCost: 125,
  creepEffect: "slow",
  requiredStructure: "darkShrine",
  productionStructure: "gateway",
});
const observer = new Unit({
  name: "observer",
  type: "army",
  speed: 2.02,
  armor: 0,
  armorType: "ground",
  baseHealth: 40,
  race: "protoss",
  buildTime: 25,
  supplyCost: 1,
  supplyProvided: 0,
  mineralCost: 25,
  gasCost: 75,
  creepEffect: "slow",
  productionStructure: "roboticsFacility",
});
const warpPrism = new Unit({
  name: "warpPrism",
  type: "army",
  speed: 2.95,
  armor: 0,
  armorType: "ground",
  baseHealth: 80,
  race: "protoss",
  buildTime: 50,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 250,
  gasCost: 0,
  creepEffect: "slow",
  productionStructure: "roboticsFacility",
});
const immortal = new Unit({
  name: "immortal",
  type: "army",
  speed: 2.25,
  attack: 20,
  attackType: "ground",
  attackRange: 6,
  attackSpeed: 1.45,
  armor: 1,
  armorType: "ground",
  baseHealth: 200,
  race: "protoss",
  buildTime: 55,
  supplyCost: 4,
  supplyProvided: 0,
  mineralCost: 275,
  gasCost: 100,
  creepEffect: "slow",
  productionStructure: "roboticsFacility",
});
const colossus = new Unit({
  name: "colossus",
  type: "army",
  speed: 2.25,
  attack: 10,
  attackType: "ground",
  attackRange: 7,
  attackSpeed: 1.5,
  armor: 1,
  armorType: "ground",
  baseHealth: 200,
  race: "protoss",
  buildTime: 75,
  supplyCost: 6,
  supplyProvided: 0,
  mineralCost: 300,
  gasCost: 200,
  creepEffect: "slow",
  requiredStructure: "roboticsBay",
  productionStructure: "roboticsFacility",
});
const disruptor = new Unit({
  name: "disruptor",
  type: "army",
  speed: 2.25,
  armor: 1,
  armorType: "ground",
  baseHealth: 100,
  race: "protoss",
  buildTime: 50,
  supplyCost: 4,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 150,
  creepEffect: "slow",
  requiredStructure: "roboticsBay",
  productionStructure: "roboticsFacility",
});
const phoenix = new Unit({
  name: "phoenix",
  type: "army",
  speed: 4.25,
  attack: 5,
  attackType: "air",
  attackRange: 5,
  attackSpeed: 1.1,
  armor: 0,
  armorType: "air",
  baseHealth: 120,
  race: "protoss",
  buildTime: 35,
  supplyCost: 2,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 100,
  maxEnergy: 200,
  productionStructure: "stargate",
});
const oracle = new Unit({
  name: "oracle",
  type: "army",
  speed: 4,
  armor: 0,
  armorType: "air",
  baseHealth: 100,
  race: "protoss",
  buildTime: 52,
  supplyCost: 3,
  supplyProvided: 0,
  mineralCost: 150,
  gasCost: 150,
  maxEnergy: 200,
  productionStructure: "stargate",
});
const voidRay = new Unit({
  name: "voidRay",
  type: "army",
  speed: 2.75,
  attack: 6,
  attackType: "air",
  attackRange: 6,
  attackSpeed: 0.5,
  armor: 0,
  armorType: "air",
  baseHealth: 150,
  race: "protoss",
  buildTime: 60,
  supplyCost: 4,
  supplyProvided: 0,
  mineralCost: 250,
  gasCost: 150,
  productionStructure: "stargate",
});
const tempest = new Unit({
  name: "tempest",
  type: "army",
  speed: 2.25,
  attack: 30,
  attackType: "air",
  attackRange: 14,
  attackSpeed: 3.3,
  armor: 2,
  armorType: "air",
  baseHealth: 200,
  race: "protoss",
  buildTime: 60,
  supplyCost: 5,
  supplyProvided: 0,
  mineralCost: 250,
  gasCost: 175,
  requiredStructure: "fleetBeacon",
  productionStructure: "stargate",
});
const carrier = new Unit({
  name: "carrier",
  type: "army",
  speed: 1.88,
  armor: 2,
  armorType: "air",
  baseHealth: 300,
  race: "protoss",
  buildTime: 90,
  supplyCost: 6,
  supplyProvided: 0,
  mineralCost: 350,
  gasCost: 250,
  requiredStructure: "fleetBeacon",
  productionStructure: "stargate",
});
const archon = new Unit({
  name: "archon",
  type: "army",
  speed: 2.81,
  attack: 25,
  attackType: "ground",
  attackRange: 3,
  attackSpeed: 1.75,
  armor: 0,
  armorType: "ground",
  baseHealth: 10,
  race: "protoss",
  buildTime: 12,
  supplyCost: 4,
  supplyProvided: 0,
  mineralCost: 0,
  gasCost: 0,
  creepEffect: "slow",
  convertedFromUnit: "highTemplar",
  convertedFromUnitCount: 2,
});
const interceptor = new Unit({
  name: "interceptor",
  type: "army",
  speed: 10.5,
  attack: 5,
  attackType: "air",
  attackRange: 8,
  attackSpeed: 3,
  armor: 0,
  armorType: "air",
  baseHealth: 40,
  race: "protoss",
  buildTime: 12,
  supplyCost: 0,
  supplyProvided: 0,
  mineralCost: 15,
  gasCost: 0,
});

export default {
  drone,
  overlord,
  queen,
  zergling,
  roach,
  hydralisk,
  mutalisk,
  corruptor,
  infestor,
  swarmHost,
  viper,
  lurker,
  ultralisk,
  ravager,
  baneling,
  overseer,
  broodLord,
  scv,
  marine,
  reaper,
  marauder,
  ghost,
  hellion,
  widowMine,
  cyclone,
  siegeTank,
  hellbat,
  thor,
  viking,
  medivac,
  liberator,
  raven,
  banshee,
  battlecruiser,
  probe,
  zealot,
  sentry,
  stalker,
  adept,
  highTemplar,
  darkTemplar,
  observer,
  warpPrism,
  immortal,
  colossus,
  disruptor,
  phoenix,
  oracle,
  voidRay,
  tempest,
  carrier,
  archon,
  interceptor,
};
