import GenerateMoveSet from "./generateMoveSet";

const ComputerTurn = (boardState, turn) => {
  const generatedMoves = GenerateMoveSet(boardState);
  const potentialMoves = generatedMoves["potentialMoves"];
  const movePiece = turn % 2 === 1 ? "X" : "O";

  const sumValues = (potentialMoves) =>
    Object.values(potentialMoves).reduce((a, b) => a + b, 0);

  const totalSum = sumValues(potentialMoves);
  const movePicker = Math.random() * totalSum;
  let cumulativeValue = 0;
  for (const key in potentialMoves) {
    cumulativeValue += potentialMoves[key];
    if (cumulativeValue >= movePicker) {
      const box = document.getElementById(`box${key}`);
      box.classList.add(`${movePiece}-Color`);
      box.querySelector(".Cell-Value").textContent = movePiece;
      boardState[key] = movePiece;
      return;
    }
  }
};

export default ComputerTurn;
