import React from "react";
import headshot from "../headshot.jpg";
import "../App.css";
import { Link } from "react-router-dom";
import PageTransition from "../functions/pageTransition";

const HeadshotStill = () => {
  const goToLinkedIn = () => {
    window.open("https://www.linkedin.com/in/daniel-hersey");
  };
  return PageTransition(
    <div className="App">
      <header className="App-header">
        <div className="Headshot-container">
          <img
            src={headshot}
            className="Still-headshot"
            alt="headshot with glowing eyes"
          />
          <Link to="/">
            <div className="Glowing-eyes-left Glowing-eyes-base"></div>
          </Link>
          <div
            className="Glowing-eyes-right Glowing-eyes-base"
            onClick={goToLinkedIn}
          ></div>
        </div>
      </header>
    </div>
  );
};

export default HeadshotStill;
