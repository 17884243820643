class Unit {
  /**
   * @param {Object} options
   * @param {string} optons.name
   * @param {string} options.type
   * @param {number} options.speed
   * @param {number} options.attack
   * @param {string} options.attackType
   * @param {number} options.attackRange
   * @param {number} options.attackSpeed
   * @param {number} options.armor
   * @param {string} options.armorType
   * @param {number} options.baseHealth
   * @param {string} options.race
   * @param {number} options.buildTime
   * @param {number} options.supplyCost
   * @param {number} options.supplyProvided
   * @param {number} options.mineralCost
   * @param {number} options.gasCost
   * @param {string} options.creepEffect
   * @param {number} options.maxEnergy
   * @param {string} options.convertedFromUnit
   * @param {number} options.convertedFromUnitCount
   * @param {string} options.requiredStructure
   * @param {string} options.productionStructure
   */

  constructor(options) {
    this.name = options.name;
    this.type = options.type;
    this.speed = options.speed; // Multiplied by game speed factor
    this.attack = options.attack;
    this.attackType = options.attackType;
    this.attackRange = options.attackRange;
    this.attackSpeed = options.attackSpeed; // Divided by game speed factor
    this.armor = options.armor;
    this.armorType = options.armorType;
    this.baseHealth = options.baseHealth;
    this.race = options.race;
    this.buildTime = options.buildTime;
    this.supplyCost = options.supplyCost;
    this.supplyProvided = options.supplyProvided;
    this.mineralCost = options.mineralCost;
    this.gasCost = options.gasCost;
    this.creepEffect = options.creepEffect;
    this.maxEnergy = options.maxEnergy;
    this.convertedFromUnit = options.convertedFromUnit;
    this.convertedFromUnitCount = options.convertedFromUnitCount;
    this.requiredStructure = options.requiredStructure;
    this.productionStructure = options.productionStructure;
  }
}

export default Unit;
