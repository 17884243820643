import React from "react";
import "../../App.css";
import PageTransition from "../../functions/pageTransition";
import HumanMove from "./humanTurn";
import CheckWin from "./checkWin";
import ComputerTurn from "./computerTurn";

const TicTacToe = () => {
  let boardState = ["", "", "", "", "", "", "", "", ""];
  let turn = 1;
  let gameWon = false;
  let computerMove = false;

  const TakeTurn = (event) => {
    if (computerMove === true) {
      return;
    } else if (gameWon === false) {
      HumanMove(event, boardState, turn, gameWon);
      gameWon = CheckWin(boardState);
      turn++;
      if (gameWon === false) {
        setTimeout(() => {
          ComputerTurn(boardState);
          turn++;
          gameWon = CheckWin(boardState);
        }, 300);
      }
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return PageTransition(
    <header className="App-header">
      <div className="Message-Box"></div>
      <div className="Tic-Tac-Toe-Grid">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((box) => (
          <div
            key={`box${box}`}
            id={`box${box}`}
            className="Tic-Tac-Toe-Box"
            style={{
              borderTopColor:
                box === 0 || box === 1 || box === 2 ? "transparent" : "",
              borderLeftColor:
                box === 0 || box === 3 || box === 6 ? "transparent" : "",
              borderRightColor:
                box === 2 || box === 5 || box === 8 ? "transparent" : "",
              borderBottomColor:
                box === 6 || box === 7 || box === 8 ? "transparent" : "",
            }}
            onClick={(event) => TakeTurn(event)}
          >
            <b className="Cell-Value">{boardState[box]}</b>
          </div>
        ))}
      </div>
      <button
        className="End-Turn-Button"
        style={{ display: "none" }}
        onClick={reloadPage}
      >
        Reset
      </button>
    </header>
  );
};

export default TicTacToe;
